import { CONFIG } from "../../utils/env";
import React, { useEffect, useState } from "react";
import { parseJwt } from "../../utils/function";
import moment from "moment";
import axios from "axios";
import AuthStore from "../../common/AuthStore";
import Unauthenticated from "../UnAuthenticated";
// import { updateState, updateRole } from "../../store/login";
import LoginPage from "../Layout/Loading";
import { MidAuthnContext } from "@mid/sdk";

interface MidProps {
    refresh(): unknown;
    accessToken: () => string | null;
    isAuthed: () => Promise<boolean>;
    login: () => Promise<MidAuthnContext>;
}

interface LoginComponentProps {
    mid: MidProps;
    children: React.ReactNode;
}

interface LoginState {
    isAuthed: boolean;
    isLoading: boolean;
}

const LoginComponent: React.FC<LoginComponentProps> = ({ mid, children }) => {
    const accessToken = localStorage.getItem("access_token") || AuthStore.accessToken;

    const [loginState, updateLoginState] = useState<LoginState>({
        isAuthed: false,
        isLoading: false,
    });
    const [isUnAuthed, setUnauthed] = useState(false);

    const { isAuthed, isLoading } = loginState;

    const checkIsAuth = (checkAuth: boolean) => {
        updateLoginState({ isAuthed: checkAuth, isLoading: false });
    };

    const checkTokenExpiration = (): boolean => {
        if (!accessToken) return false;
        const infoFromToken = parseJwt(accessToken);
        const { exp = 0 } = infoFromToken;
        const { exp: userExp = 0 } = infoFromToken || {};
        return exp - moment().unix() > 0 || userExp - moment().unix() > 0;
    };

    useEffect(() => {
        if (accessToken && checkTokenExpiration()) {
            checkIsAuth(true);
        } else if (window.location.pathname.includes("/auth/callback")) {
            mid.isAuthed().then((loginStatus: boolean) => {
                checkIsAuth(loginStatus);
            });
        } else {
            checkIsAuth(false);
        }
    }, []);

    const makeUserLogin = () => {
        mid.isAuthed().then((loginStatus: boolean) => {
            if (loginStatus) {
                checkValidateUser();
            } else {
                checkIsAuth(false);
            }
        });
    };

    const checkValidateUser = async () => {
        const midToken = mid.accessToken();
        if (midToken) {
            const infoFromToken = parseJwt(midToken);
            const { auth_time = 0 } = infoFromToken;
            try {
                const response = await axios.request({
                    method: "GET",
                    url: `${CONFIG.TOKEN_VALIDATION}`,
                    headers: {
                        Authorization: `Bearer ${midToken}`,
                    },
                });
                const { status = "" } = response;
                if (status === 200) {
                    AuthStore.midToken = midToken;
                    AuthStore.auth_time = auth_time;
                    AuthStore.accessToken = midToken;
                    checkIsAuth(true);
                }
            } catch (error) {
                setUnauthed(true);
            }
        } else {
            checkIsAuth(false);
        }
    };

    const submitHandler = () => {
        updateLoginState({ ...loginState, isLoading: true });
        const access_token: string | null = mid.accessToken();
        const token = access_token || "";
        const infoFromToken = parseJwt(token);
        const { exp = 0 } = infoFromToken;
        if (exp - moment().unix() > 0) {
            checkValidateUser();
        } else {
            mid.login().finally(() => {
                makeUserLogin();
            });
        }
    };

    if (isAuthed) {
        return <>{children}</>;
    } else if (isUnAuthed) {
        return <Unauthenticated />;
    }

    return <LoginPage onLogin={submitHandler} isLoading={isLoading} errorMsg={""} />;
};

export default LoginComponent;
