import { parseJwt } from "../utils/function";

interface ParsedData {
    name?: string;
    role?: string;
    last_login_at?: string;
}

class AuthStore {
    private _data!: Storage; // Use definite assignment assertion
    private static instance: AuthStore;

    constructor() {
        if (!AuthStore.instance) {
            this._data = localStorage;
            AuthStore.instance = this;
        }
        return AuthStore.instance;
    }

    set accessToken(val: string | null) {
        if (val) {
            this._data.setItem("access_token", val);
        } else {
            this._data.removeItem("access_token");
        }
    }

    get accessToken(): string | null {
        return this._data.getItem("access_token");
    }

    set refreshToken(val: string | null) {
        if (val) {
            this._data.setItem("refresh_token", val);
        } else {
            this._data.removeItem("refresh_token");
        }
    }

    get refreshToken(): string | null {
        return this._data.getItem("refresh_token");
    }

    set midToken(val: string) {
        this._data.setItem("midToken", val);
    }

    set auth_time(val: string) {
        this._data.setItem("auth_time", val);
    }

    set redirectionURL(val: string | null) {
        if (val) {
            this._data.setItem("redirect", val);
        } else {
            this._data.removeItem("redirect");
        }
    }

    get redirectionURL(): string | null {
        return this._data.getItem("redirect");
    }

    get parsedData(): ParsedData | null {
        const token = this._data.getItem("access_token");
        return token ? parseJwt(token) : null;
    }

    get FullName(): string {
        const { name = "" } = this.parsedData || {};
        return name;
    }

    get name(): string {
        const { name: username = "" } = this.parsedData || {};
        return username ? `${username[0]?.toUpperCase()}${username.slice(1)}` : "";
    }

    get initials(): string {
        const { name: clientname = "" } = this.parsedData || {};
        return clientname ? this.getInitials(clientname) : "";
    }

    private getInitials(name: string): string {
        const splitName = name.split(" ");
        const initials =
            splitName.length >= 2
                ? `${splitName[0][0]?.toUpperCase()}${splitName[1][0]?.toUpperCase()}`
                : splitName[0][0]?.toUpperCase() || "";
        return initials;
    }
}

const instance = new AuthStore();
Object.freeze(instance);

export default instance;
