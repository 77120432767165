import React from "react";
import "./Loading.css";
import Loader from "../Loader";

interface LoadingProps {
    onLogin: () => void;
    isLoading: boolean;
    errorMsg?: string;
}

const LoadingPage: React.FC<LoadingProps> = ({ onLogin, isLoading, errorMsg }) => (
    <div className="LoadingPage">
        <div className="content">
            <h2>Welcome to My Solution</h2>
            {errorMsg && <h4 className="error-msg">{errorMsg}</h4>}
            <button id="login" onClick={onLogin} className={`btn ${isLoading ? "btn-loading" : ""}`}>
                {isLoading ? <Loader /> : "Login"}
            </button>
        </div>
    </div>
);

export default LoadingPage;
