const ENV = process.env.REACT_APP_ENVIRONMENT as string;

let API_BASE_PATH = "/";
let REACT_APP_REDIRECT_BASE_URL = "http://localhost:3000";
let REACT_APP_OIDC_CONFIG_URL = "https://auth.int.mckinsey.id/auth/realms/r/.well-known/openid-configuration";
const REACT_APP_CLIENT_ID = "6dfe1a15-5292-4179-84e2-64b7ea71ce0c";
let REACT_TOKEN_AUTHENTICATION = "https://auth.int.mckinsey.id/auth/realms/r/protocol/openid-connect/userinfo";
let REACT_GET_TOKEN_URL = "https://auth.int.mckinsey.id/auth/realms/r/protocol/openid-connect/token";

if (ENV === "local") {
    REACT_APP_REDIRECT_BASE_URL = "http://localhost:3000";
    API_BASE_PATH = "http://localhost:8000";
}

if (ENV === "stg") {
    REACT_APP_REDIRECT_BASE_URL = "/";
    API_BASE_PATH = "/";
}

if (ENV === "prod") {
    REACT_APP_REDIRECT_BASE_URL = "";
    API_BASE_PATH = "";
    REACT_APP_OIDC_CONFIG_URL = "https://auth.mckinsey.id/auth/realms/r/.well-known/openid-configuration";
    REACT_TOKEN_AUTHENTICATION = "https://auth.mckinsey.id/auth/realms/r/protocol/openid-connect/userinfo";
    REACT_GET_TOKEN_URL = "https://auth.mckinsey.id/auth/realms/r/protocol/openid-connect/token";
}

export const CONFIG = {
    redirectBaseUrl: REACT_APP_REDIRECT_BASE_URL,
    oidcConfigUrl: REACT_APP_OIDC_CONFIG_URL,
    clientId: REACT_APP_CLIENT_ID,
    apiBasePath: API_BASE_PATH,
    TOKEN_VALIDATION: REACT_TOKEN_AUTHENTICATION,
    TOKEN_URL: REACT_GET_TOKEN_URL,
};

export const PATHS = {
    AUTH_CALL: "/auth/callback",
    HOME_PAGE: "/",
};
