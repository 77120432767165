import React from "react";
import { Mid } from "@mid/sdk";
import { Link } from "react-router-dom";

import "./App.css";

const App: React.FC<{ mid: Mid }> = ({ mid }) => (
    <div className="Page">
        <header className="Page-header">
            <div className="Page-header-title">
                <h3>
                    <Link to="/">My Solution</Link>
                </h3>
                <button className="btn" onClick={() => mid.logout()}>
                    Logout
                </button>
            </div>
        </header>
        <section className="Page-content">
            <section className="Page-content-panel">MENU</section>
            <section className="Page-content-center" />
            <section className="Page-content-panel">OPTIONS</section>
        </section>
    </div>
);

export default App;
