import React from "react";
// import { useSelector } from "react-redux";
import { Mid } from "@mid/sdk";
import { PATHS } from "./utils/env";
import { Routes, Route } from "react-router-dom";
import CallbackComponent from "./components/CallbackComponent";
import App from "./components/Layout/App";

interface Props {
    mid: Mid;
}

const RoutesComponent = ({ mid }: Props) => {
    return (
        <Routes>
            <Route path={PATHS.AUTH_CALL} element={<CallbackComponent mid={mid} />} />
            <Route
                path={PATHS.HOME_PAGE}
                element={
                    <div className="App">
                        <App mid={mid} />
                    </div>
                }
            ></Route>
        </Routes>
    );
};

export default React.memo(RoutesComponent);
