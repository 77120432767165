import React from "react";
import { Box, Typography } from "@mui/material";

const Unauthenticated: React.FC = () => {
    return (
        <Box
            className="unauthenticatedPage"
            style={{ left: 0, position: "absolute", margin: 0, right: 0, top: "20vh" }}
            data-testid="unauthenticated"
        >
            <Box
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: 500,
                }}
            >
                <Typography className="heading-1">{"Mckinsey & Company"}</Typography>
                <br />
                <Typography className="unauth-text">You&apos;re not authorized to access this page.</Typography>
                <Typography className="unauth-text">Please contact your administrator for permission.</Typography>
            </Box>
        </Box>
    );
};

export default Unauthenticated;
