import { combineReducers } from "redux";

// Define the Shape of Your State
type State = {
    user: UserState;
    appSettings: AppSettingsState;
};

type UserState = {
    username: string;
    loggedIn: boolean;
};

type AppSettingsState = {
    theme: string;
    language: string;
};

type Action = { type: string; payload?: any };

// Example Reducer for User
const userReducer = (state: UserState = { username: "", loggedIn: false }, action: Action): UserState => {
    switch (action.type) {
        case "LOGIN":
            return { ...state, username: action.payload.username, loggedIn: true };
        case "LOGOUT":
            return { ...state, username: "", loggedIn: false };
        default:
            return state;
    }
};

// Example Reducer for App Settings
const appSettingsReducer = (
    state: AppSettingsState = { theme: "light", language: "en" },
    action: Action
): AppSettingsState => {
    switch (action.type) {
        case "SET_THEME":
            return { ...state, theme: action.payload.theme };
        case "SET_LANGUAGE":
            return { ...state, language: action.payload.language };
        default:
            return state;
    }
};

// Combine Reducers
const appReducer = combineReducers({
    user: userReducer,
    appSettings: appSettingsReducer,
});

// Root Reducer Typed for State and Action
export const reducer = (state: State | undefined, action: Action): State => appReducer(state, action);

// Middleware Setup (If any)
export const middlewares = [
    // Include middlewares here like Redux Thunk or other custom middlewares
];
