import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { parseJwt } from "../../utils/function";
import AuthStore from "../../common/AuthStore";
import Loader from "../Loader";
import tokenApi from "../../utils/tokenApi";
import Unauthenticated from "../UnAuthenticated";
import Endpoints from "../../Endpoint";
import { MidAuthnContext } from "@mid/sdk";

interface MidProps {
    accessToken: () => string | null;
    isAuthed: () => Promise<boolean>;
    login: () => Promise<MidAuthnContext>;
}

interface CallbackComponentProps {
    mid: MidProps;
    children?: React.ReactNode;
}

const CallbackComponent: React.FC<CallbackComponentProps> = ({ mid }) => {
    const history = useNavigate();
    const [status, setStatus] = useState<boolean>(false);

    const checkvalidateuser = async () => {
        try {
            const midToken = mid.accessToken();
            if (midToken) {
                const response = await tokenApi(`${Endpoints.apiBasePath}${Endpoints.verifyUser}`, "GET", midToken);
                if (response) {
                    setStatus(false);
                    const infoFromToken = parseJwt(midToken);
                    const { auth_time = 0 } = infoFromToken;
                    AuthStore.midToken = midToken;
                    AuthStore.auth_time = auth_time;
                    AuthStore.accessToken = midToken;
                    redirectURL();
                }
            }
        } catch (error) {
            console.error("Exception occurred...!");
            console.error(error);
            setStatus(true);
        }
    };

    useEffect(() => {
        const midToken = mid.accessToken();
        if (midToken) {
            checkvalidateuser();
        }
    }, []);

    const redirectURL = () => {
        const redirectUrl = AuthStore.redirectionURL;
        history("/");
        AuthStore.redirectionURL = redirectUrl;
    };

    if (status) {
        return <Unauthenticated />;
    }

    return (
        <Box
            style={{
                width: "100vw",
                height: "100vh",
            }}
            data-testid="call"
        >
            <Box
                style={{
                    position: "absolute",
                    left: "45vw",
                    top: "45vh",
                }}
            >
                <Loader position="fixed" showBackground background="transparent" />
            </Box>
        </Box>
    );
};

export default CallbackComponent;
