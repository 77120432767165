import { GET } from "./constants";

interface PayloadObj {
    client_id: string;
    client_secret: string;
    grant_type: string;
}

async function tokenApi(
    url: string,
    method: string = GET,
    token?: string,
    payload: PayloadObj = { client_id: "", client_secret: "", grant_type: "" }
) {
    try {
        const payloadData = new URLSearchParams(Object.entries(payload));

        const headers: HeadersInit = {
            "Content-Type": "application/x-www-form-urlencoded",
            ...(token && { Authorization: `Bearer ${token}` }),
        };

        const options: RequestInit = {
            method: method,
            headers,
            ...(method !== "GET" && { body: payloadData }),
        };

        const response = await fetch(`${url}`, options);
        if (!response.ok) {
            if (response.status === 401) {
                throw new Error("Signature Expired");
            }
            throw new Error("Failed to fetch data");
        }
        return response.json();
    } catch (error: any) {
        throw new Error(error.message || "Failed to fetch data");
    }
}

export default tokenApi;
