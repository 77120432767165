import React from "react";
import "./App.css";
import { Mid } from "@mid/sdk";
import RoutesComponent from "./RoutesComponent";
import LoginComponent from "./components/LoginComponent";

interface Props {
    mid: Mid;
}

function App({ mid }: Props) {
    return (
        <LoginComponent mid={mid}>
            <RoutesComponent mid={mid} />
        </LoginComponent>
    );
}

export default App;
